import appStrings from '@/app/utility/string.utility';
import modules from '../../responsibility/searchModule';
export default {
  name: 'AddSubModule',
  props: ['subModuleRowData'],
  components: {
    modules
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showModuleList: false,
      showSubModuleList: false,
      module: {
        value: null,
        text: null
      },
      subModuleData: [
        {
          sub_module_name: null,
          sub_module_id: 0,
          sub_module_desc: null
        }
      ],
      fields: [
        {
          key: 'sub_module_name',
          label: 'Sub Module'
        },
        {
          key: 'sub_module_desc'
        },
        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      subModuleOptions: [],
      subModuleOptionsFields: [
        {
          key: 'text',
          label: 'Sub Module'
        }
      ]
    };
  },
  mounted() {
    if (this.subModuleRowData) {
      this.module = {
        value: this.subModuleRowData.module_id,
        text: this.subModuleRowData.module_name
      };
      this.getSubModuleListById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if(actionName === 'save' && this.editMode) {
          this.addEditSubModule();
        }
      }
    });
  },
  methods: {
    getSubModuleListById() {
      const payload = {
        moduleId: this.module.value
      };
      this.loader = true;
      this.$store
        .dispatch('subModule/getSubModuleListById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            if (
              results.sub_module_details &&
              results.sub_module_details.length > 0
            ) {
              this.subModuleData = results.sub_module_details;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditSubModule() {
      const details = this.subModuleData.map(elem => {
        return {
          sub_module_name: elem.sub_module_name,
          sub_module_id: elem.sub_module_id,
          sub_module_desc: elem.sub_module_desc
        };
      });
      const payload = {
        moduleId: this.module.value,
        sub_module_details: { sub_module_details: details ? details : null }
      };
      this.loader = true;
      this.$store.dispatch('subModule/addEditSubModule', payload)
      .then(response => {
        this.loader = false;
        this.showAlert = true;
        if(response.status === 201) {
          this.isSuccess = true;
          this.responseMsg = response.data.message;
        } else {
          this.isSuccess = false;
          this.responseMsg = response.response.data.message;
        }
      })
      .catch(()=> {
        this.loader = false;
        this.showAlert = false;
        this.isSuccess = false;
        this.responseMsg = appStrings.autoFailedMsg;
      });
    },
    openModuleList() {
      this.showModuleList = true;
    },
    selectedModule(moduleData) {
      this.module.text = moduleData.module_name;
      this.module.value = moduleData.module_id;
      this.showModuleList = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.subModuleData.push({
          sub_module_name: null,
          sub_module_id: 0,
          sub_module_desc: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.moduleData.splice(index, 1);
      }
    }
  },
  beforeDestroy() {}
};
