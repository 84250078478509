import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import modules from '../responsibility/searchModule';
import addModule from './addSubModule';
export default {
  name: 'SubModule',
  watch: {
    currentPage: function() {
      this.getSubModuleList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSubModuleList();
    },
    'module.text': function() {
      this.subModule = {
        value: null,
        text: null
      };
    }
  },
  components: {
    modules,
    addModule
  },
  data() {
    return {
      unsubscribe: null,
      showAddModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      showModuleList: false,
      showSubModuleList: false,
      module: {
        value: null,
        text: null
      },
      subModule: {
        value: null,
        text: null
      },
      subModuleRowData: null,
      subModuleData: [],
      subModuleDataFields: [
        {
          key: 'sub_module_name',
          label: 'Sub Module'
        },
        {
          key: 'module_name',
          label: 'Module'
        },
        {
          key: 'created_by_name',
          label: 'Created By'
        },
        {
          key: 'creation_date'
        }
      ],
      subModuleOptions: [],
      subModuleOptionsFields: [
        {
          key: 'text',
          label: 'Sub Module'
        }
      ]
    };
  },
  mounted() {
    this.getSubModuleList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddModal = true;
          this.subModuleRowData = null;
        }
      }
    });
  },
  methods: {
    getSubModuleList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.module.value,
        sub_module_id: this.subModule.value
      };
      this.loader = true;
      this.$store
        .dispatch('subModule/getSubModuleList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.subModuleData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.module = {
        value: null,
        text: null
      };
      this.subModule = {
        value: null,
        text: null
      };
      this.subModuleOptions = [];
      this.getSubModuleList();
    },
    rowSelected(item) {
      this.subModuleRowData = item;
      this.showAddModal = true;
    },
    openModuleList() {
      this.showModuleList = true;
    },
    selectedModule(moduleData) {
      this.module.text = moduleData.module_name;
      this.module.value = moduleData.module_id;
      this.showModuleList = false;
      this.getSubModuleLov();
    },
    getSubModuleLov() {
      const payload = { module_id: this.module.value };
      this.loader = true;
      this.$store
        .dispatch('docSetup/getSubModuleLov', payload)
        .then(response => {
          this.loader = false;
          const result = response.data.data;
          const sub_module_arr = result.map(result => {
            return {
              value: result.sub_module_id,
              text: result.sub_module_name
            };
          });
          this.subModuleOptions = sub_module_arr;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openSubModuleList() {
      if (!this.module.value) {
        return alert(appStrings.DEPENDENTVSETMSG.MODULEMSG);
      }
      this.showSubModuleList = true;
    },
    selectedSubModule(item) {
      this.subModule = {
        value: item.value,
        text: item.text
      };
      this.showSubModuleList = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
